import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function (location) {
  return originalPush.call(this, location).catch((err) => err);
};

const viewport = {
  content: "width=device-width, initial-scale=1.0, user-scalable=no",
};

const routes = [
  { path: "/", redirect: "/Login" },
  {
    path: "/createWorkFlow",
    component: () => import("@/views/workFlow/index.vue"),
  },
  {
    path: "/FlowPanel",
    name: "FlowPanel",
    component: () => import("../views/flowPanel/index.vue"),
  },
  {
    path: "/markingPapers",
    name: "examination/markingPapers",
    component: () => import("../views/examination/markingPapers"),
  },
  {
    path: "/evaluation",
    name: "evaluation",
    component: () => import("../views/evaluation/index"),
  },
  //首页
  {
    path: "/home",
    name: "home",
    component: () => import("../views/home"),
  },
  {
    path: "/AiTalentProfileReport",
    name: "AiTalentProfileReport",
    component: () => import("../views/AiTalentProfileReport"),
  },
  // 培训效能评估
  {
    path: "/train",
    name: "train",
    component: () => import("../components/TrainSmallScreen/trainPlacard.vue"),
  },
  {
    path: "/aiInterviewReport",
    name: "aiInterviewReport",
    component: () => import("../views/AiInterviewReport"),
  },
  {
    path: "/look",
    name: "look",
    component: () => import("../views/look"),
  },
  {
    path: "/decompositionTree",
    name: "decompositionTree",
    component: () => import("../views/DecompositionTree"),
  },
  // 个人首页
  // {
  //   path: "/personalHomepage",
  //   name: "personalHomepage",
  //   component: () => import("../views/personalHomepage"),
  // },
  //首页大屏数据穿透
  {
    path: "/DataPenetration",
    name: "DataPenetration",
    component: () => import("../views/DataPenetration/index.vue"),
  },
  // 登入
  {
    path: "/Login",
    name: "Login",
    auth: false,
    component: () => import("../views/Login"),
  },
  {
    //培训-培训课程表
    path: "/Train/TrainingRating",
    name: "Train/TrainingRating",
    component: () => import("../views/ArcTrain/TrainingRating"),
  },
  {
    // 复职登记
    path: "/BeReinstated",
    name: "BeReinstated",
    component: () => import("../views/BeReinstated"),
  },
  {
    path: "/VideoInterviewSystem",
    name: "VideoInterviewSystem",
    component: () => import("../components/Resume/VideoInterviewSystem.vue"),
  },
  {
    path: "/talentProfileAdditionSystem",
    name: "talentProfileAdditionSystem",
    component: () =>
      import("../views/Recruitment/talentProfileAdditionSystem.vue"),
  },
  {
    path: "/TestAccountManagement",
    name: "TestAccountManagement",
    component: () => import("../views/TestAccountManagement/index.vue"),
  },
  // Ai咨询
  {
    path: "/Consult",
    name: "Consult",
    component: () => import("../views/Consult"),
    children: [
      {
        // 顶层设计
        path: "/Consult/TopLevelDesign",
        name: "Consult/TopLevelDesign",
        component: () => import("../views/Consult/TopLevelDesign"),
      },
      {
        // 战略规划
        path: "/Consult/StrategicPlanning",
        name: "Consult/StrategicPlanning",
        component: () => import("../views/Consult/StrategicPlanning"),
      },
      {
        // 商业模式
        path: "/Consult/BusinessModel",
        name: "Consult/BusinessModel",
        component: () => import("../views/Consult/BusinessModel"),
      },
      {
        // 组织管理
        path: "/Consult/OrganizationalManagement",
        name: "Consult/OrganizationalManagement",
        component: () => import("../views/Consult/OrganizationalManagement"),
      },
      {
        // 流程再造
        path: "/Consult/BPR",
        name: "Consult/BPR",
        component: () => import("../views/Consult/BPR"),
      },
      {
        // 人才规划
        path: "/Consult/TalentPlanning",
        name: "Consult/TalentPlanning",
        component: () => import("../views/Consult/TalentPlanning"),
      },
      {
        path: "/Consult/AIPerformance",
        name: "Consult/AIPerformance",
        component: () => import("../views/Consult/AIPerformance"),
      },
      {
        path: "/Consult/AISalary",
        name: "Consult/AISalary",
        component: () => import("../views/Consult/AISalary"),
      },
      {
        path: "/Consult/AITrain",
        name: "Consult/AITrain",
        component: () => import("../views/Consult/AITrain"),
      },
      {
        path: "/Consult/AIRecruitment",
        name: "Consult/AIRecruitment",
        component: () => import("../views/Consult/AIRecruitment"),
      },
      {
        path: "/Consult/tagList",
        name: "Consult/tagList",
        component: () => import("../views/Consult/tagList"),
      },
    ],
  },
  //luckySheet表格
  {
    path: "/luckySheet",
    name: "luckySheet",
    component: () => import("../views/Salary/SalaryLucky.vue"),
  },
  {
    path: "/TrainingCheckIn",
    name: "TrainingCheckIn",
    component: () => import("../views/signIn.vue"),
  },
  {
    path: "/office",
    name: "office",
    component: () => import("../views/office.vue"),
  },
  {
    path: "/recruitmentVisualizationReport",
    name: "recruitmentVisualizationReport",
    component: () =>
      import("../views/Recruitment/recruitmentVisualizationReport"),
  },
  {
    path: "/chat",
    name: "chat",
    component: () => import("../views/Recruitment/chat.vue"),
  },
  {
    path: "/sendpostion",
    name: "sendpostion",
    component: () => import("../components/Resume/sendpostion.vue"),
  },
  {
    path: "/qcwychat",
    name: "qcwychat",
    component: () => import("../components/Resume/qcwy.vue"),
  },
  {
    path: "/qcdata",
    name: "qcdata",
    component: () => import("../views/Recruitment/qcresume.vue"),
  },
  {
    path: "/getresume",
    name: "getresume",
    component: () => import("../views/Recruitment/getresume.vue"),
  },
  //招聘
  {
    path: "/RecruitmentModule",
    name: "RecruitmentModule",
    component: () => import("../views/RecruitmentModule"),
    children: [
      // // 招聘管理
      // {
      //   // 招聘
      //   path: "/Recruitment/recruitmentplan",
      //   name: "Recruitment/recruitmentplan",
      //   component: () => import("../views/Recruitment/Recruitmentsetup/recruitmentplan.vue"),
      // },
      // {
      //   // 招聘申请
      //   path: "/Recruitment/Recruitmentapplication",
      //   name: "Recruitment/Recruitmentapplication",
      //   component: () => import("../views/Recruitment/Recruitmentsetup/Recruitmentapplication.vue"),
      // },
      // {
      //   // 部门评估
      //   path: "/Recruitment/Departmental",
      //   name: "Recruitment/Departmental",
      //   component: () => import("../views/Recruitment/Recruitmentsetup/Departmental.vue"),
      // },
      // {
      //   // 面试评价
      //   path: "/Recruitment/interviewassessment",
      //   name: "Recruitment/interviewassessment",
      //   component: () => import("../views/Recruitment/Recruitmentsetup/interviewassessment.vue"),
      // },
      // {
      //   // 人才决策
      //   path: "/Recruitment/recruitTalentdecision",
      //   name: "Recruitment/recruitTalentdecision",
      //   component: () => import("../views/Recruitment/Recruitmentsetup/recruitTalentdecision.vue"),
      // },
      // {
      //   // 链接入职审批流程
      //   path: "/Recruitment/interlinkage",
      //   name: "Recruitment/interlinkage",
      //   component: () => import("../views/Recruitment/Recruitmentsetup/interlinkage.vue"),
      // },

      // 招聘设置
      // {
      //   // 邮箱绑定
      //   path: "/Recruitment/Mailboxbinding",
      //   name: "Recruitment/Mailboxbinding",
      //   component: () => import("../views/Recruitment/Recruitmentsetup/Mailboxbinding.vue"),
      // },
      // {
      //   // 面试通知模板设定
      //   path: "/Recruitment/interviewnotice",
      //   name: "Recruitment/interviewnotice",
      //   component: () => import("../views/Recruitment/Recruitmentsetup/interviewnotice.vue"),
      // },
      // {
      //   // 网站管理
      //   path: "/Recruitment/Websitenotice",
      //   name: "Recruitment/Websitenotice",
      //   component: () => import("../views/Recruitment/Recruitmentsetup/Websitenotice.vue"),
      // },
      // {
      //   // 人才决策设置
      //   path: "/Recruitment/Talentdecision",
      //   name: "Recruitment/Talentdecision",
      //   component: () => import("../views/Recruitment/Recruitmentsetup/Talentdecision.vue"),
      // },
      // {
      //   // 人才画像
      //   path: "/Recruitment/PortraiTtalent",
      //   name: "Recruitment/PortraiTtalent",
      //   component: () => import("../views/Recruitment/Recruitmentsetup/PortraiTtalent.vue"),
      // },
      // {
      //   // 自定义题目
      //   path: "/Recruitment/custom",
      //   name: "Recruitment/custom",
      //   component: () => import("../views/Recruitment/Recruitmentsetup/custom.vue"),
      // },
      {
        path: "/Recruitment/recruitingPlan",
        name: "Recruitment/recruitingPlan",
        component: () => import("../views/Recruitment/recruitingPlan.vue"),
      },
      {
        path: "/Recruitment/referenceCheck",
        name: "Recruitment/referenceCheck",
        component: () => import("../views/Recruitment/referenceCheck"),
      },
      {
        path: "/Recruitment/userQuestionBankAdd",
        name: "Recruitment/userQuestionBankAdd",
        component: () => import("../views/Recruitment/UserQuestionBankAdd"),
      },
      {
        path: "/Recruitment/viewCandidates",
        name: "Recruitment/viewCandidates",
        component: () => import("../views/Recruitment/viewCandidates"),
      },

      {
        path: "/Recruitment/talentProfileAddition",
        name: "Recruitment/talentProfileAddition",
        component: () => import("../views/Recruitment/talentProfileAddition"),
      },
      {
        path: "/Recruitment/WisdomPrimary",
        name: "Recruitment/WisdomPrimary",
        component: () => import("@/views/Recruitment/WisdomPrimary"),
      },
      {
        path: "/Recruitment/RecruitmentReport",
        name: "Recruitment/RecruitmentReport",
        component: () => import("@/views/Recruitment/RecruitmentReport"),
      },
      {
        path: "/Recruitment/customerManagement",
        name: "Recruitment/customerManagement",
        component: () => import("@/views/Recruitment/customerManagement"),
      },
      {
        path: "/Recruitment/recruitmentProject",
        name: "Recruitment/recruitmentProject",
        component: () => import("@/views/Recruitment/recruitmentProject"),
      },
      // 自动化
      {
        path: "/Recruitment/automate",
        name: "Recruitment/automate",
        component: () => import("@/views/Recruitment/automate"),
      },
      {
        // 招聘话术
        path: "/Recruitment/Techniques",
        name: "Recruitment/Techniques",
        component: () => import("@/views/Recruitment/Techniques.vue"),
      },
      // 面试评估报告 Evaluation report
      {
        path: "/Recruitment/evaluationReport",
        name: "Recruitment/evaluationReport",
        component: () =>
          import("@/views/Recruitment/components/evaluationReport.vue"),
      },
      // 背景调查
      {
        path: "/Recruitment/backgroundCheck",
        name: "Recruitment/backgroundCheck",
        component: () => import("@/views/Recruitment/backgroundCheck.vue"),
      },
      // 宣讲会
      {
        path: "/Recruitment/lecture",
        name: "Recruitment/lecture",
        component: () => import("@/views/Recruitment/lecture.vue"),
      },
      {
        path: "/Recruitment/aiFollowUp",
        name: "Recruitment/aiFollowUp",
        component: () => import("@/views/Recruitment/aiFollowUp.vue"),
      },
      // {
      //   path: "/Recruitment/lectureList",
      //   name: "Recruitment/lectureList",
      //   component: () => import("@/views/Recruitment/lectureList.vue"),
      // },
      // 二维码列表
      {
        path: "/Recruitment/QRcodeList",
        name: "Recruitment/QRcodeList",
        component: () => import("@/views/Recruitment/QRcodeList.vue"),
      },
    ],
  },
  // 绩效
  {
    path: "/PerformanceModule",
    name: "PerformanceModule",
    component: () => import("../views/PerformanceModule"),
    children: [
      // {
      //   //行业参照模板
      //   path: "/Performance/PerformAssessment",
      //   name: "/Performance/PerformAssessment",
      //   component: () => import("../views/Performance/PerformAssessment.vue"),
      // },
      {
        //指标档案库
        path: "/Performance/assessmentsetting",
        name: "/Performance/assessmentsetting",
        component: () => import("../views/Performance/assessmentsetting.vue"),
      },
      // // 绩效管理
      // {
      // //数据抓取
      //   path: "/Performance/datacapture",
      //   name: "/Performance/datacapture",
      //   component: () => import("../views/Performance/ProjectMangement/datacapture.vue"),
      // },
      // {
      //   //发起考核
      //   path: "/Performance/initiated",
      //   name: "/Performance/initiated",
      //   component: () => import("../views/Performance/ProjectMangement/initiated.vue"),
      // },
      // {
      //   //考核评价
      //   path: "/Performance/evaluation",
      //   name: "/Performance/evaluation",
      //   component: () => import("../views/Performance/checkEvaluate.vue"),
      // },
      // 项目管理
      {
        //我发起的
        path: "/Performance/myinitiated",
        name: "/Performance/myinitiated",
        component: () =>
          import("../views/Performance/ProjectMangement/myinitiated.vue"),
      },
      {
        //我负责的
        path: "/Performance/responsible",
        name: "/Performance/responsible",
        component: () =>
          import("../views/Performance/ProjectMangement/responsible.vue"),
      },
      {
        //我参与的
        path: "/Performance/mypart",
        name: "/Performance/mypart",
        component: () =>
          import("../views/Performance/ProjectMangement/mypart.vue"),
      },
      {
        //我完成的
        path: "/Performance/finished",
        name: "/Performance/finished",
        component: () =>
          import("../views/Performance/ProjectMangement/finished.vue"),
      },
      {
        //接受待处理
        path: "/Performance/putonhold",
        name: "/Performance/putonhold",
        component: () =>
          import("../views/Performance/ProjectMangement/putonhold.vue"),
      },
      {
        //完成待处理
        path: "/Performance/completepending",
        name: "/Performance/completepending",
        component: () =>
          import("../views/Performance/ProjectMangement/completepending.vue"),
      },
      {
        //我的退回
        path: "/Performance/myreturn",
        name: "/Performance/myreturn",
        component: () =>
          import("../views/Performance/ProjectMangement/myreturn.vue"),
      },
      {
        //绩效考核
        path: "/Performance/visualOKR",
        name: "Performance/visualOKR",
        component: () => import("../views/Performance/visualOKR"),
      },
      {
        //绩效lucky表格查看
        path: "/Performance/PerformanceIndicators",
        name: "Performance/PerformanceIndicators",
        component: () => import("../views/Performance/PerformanceIndicators"),
      },
      //  {
      //   //报表下载
      //   path: "/Performance/ReportDownload",
      //   name: "Performance/ReportDownload",
      //   component: () => import("../views/Performance/ReportDownload"),
      // }, //OKR
      // {
      //   path: "/Performance/OKR",
      //   name: "Performance/OKR",
      //   component: () => import("../views/OKR/target/index.vue"),
      // },
      // // 任务
      // {
      //   path: "/Performance/quest",
      //   name: "Performance/quest",
      //   component: () => import("../views/OKR/quest/index.vue"),
      // },
      // // 目标对齐
      // {
      //   path: "/Performance/aligning",
      //   name: "Performance/aligning",
      //   component: () => import("../views/OKR/aligning/index.vue"),
      // },
    ],
  },
  //培训
  {
    path: "/TrainModule",
    name: "TrainModule",
    component: () => import("../views/TrainModule"),
    children: [
      // //培训设置
      // {
      //   // 建班分组
      //     path: "/Train/TrainClassgroup",
      //     name: "Train/TrainClassgroup",
      //     component: () => import("../views/ArcTrain/TrainComponents/TrainClassgroup.vue"),
      // },
      // {
      //   // 培训安排
      //     path: "/Train/TrainingArrangement",
      //     name: "Train/TrainingArrangement",
      //     component: () => import("../views/ArcTrain/TrainComponents/TrainingArrangement.vue"),
      // },
      // {
      //   // 培训签到管理
      //     path: "/Train/Traincheckin",
      //     name: "Train/Traincheckin",
      //     component: () => import("../views/ArcTrain/TrainComponents/Traincheckin.vue"),
      // },
      // //训练
      // {
      //   // 课程管理
      //     path: "/Train/courseware",
      //     name: "Train/courseware",
      //     component: () => import("../views/ArcTrain/TrainComponents/courseware.vue"),
      // },
      // {
      //   // 试题管理
      //     path: "/Train/Testmanagement",
      //     name: "Train/Testmanagement",
      //     component: () => import("../views/ArcTrain/TrainComponents/Testmanagement.vue"),
      // },
      // {
      //   // 智能考试
      //     path: "/Train/Intelligentexamination",
      //     name: "Train/Intelligentexamination",
      //     component: () => import("../views/ArcTrain/TrainComponents/Intelligentexamination.vue"),
      // },
      // {
      //   // 培训评估
      //     path: "/Train/evaluationtraining",
      //     name: "Train/evaluationtraining",
      //     component: () => import("../views/ArcTrain/TrainComponents/evaluationtraining.vue"),
      // },
      // {
      //   // 智能练习
      //     path: "/Train/dirigation",
      //     name: "Train/dirigation",
      //     component: () => import("../views/ArcTrain/TrainComponents/dirigation.vue"),
      // },
      // //培训管理
      // {
      //   // 培训申请
      //     path: "/Train/Trainapplication",
      //     name: "Train/Trainapplication",
      //     component: () => import("../views/ArcTrain/TrainComponents/Trainapplication.vue"),
      // },
      // {
      //   // 课件管理
      //     path: "/Train/TrainCourseware",
      //     name: "Train/TrainCourseware",
      //     component: () => import("../views/ArcTrain/TrainComponents/TrainCourseware.vue"),
      // },
      // {
      //   // 外部讲师管理
      //     path: "/Train/TrainExternal",
      //     name: "Train/TrainExternal",
      //     component: () => import("../views/ArcTrain/TrainComponents/TrainExternal.vue"),
      // },
      // {
      //   // 内部讲师管理
      //     path: "/Train/Trainlecturer",
      //     name: "Train/Trainlecturer",
      //     component: () => import("../views/ArcTrain/TrainComponents/Trainlecturer.vue"),
      // },
      // {
      //   // 学分管理
      //     path: "/Train/TrainCredithour",
      //     name: "Train/TrainCredithour",
      //     component: () => import("../views/ArcTrain/TrainComponents/TrainCredithour.vue"),
      // },
      // {
      //   // 积分管理
      //     path: "/Train/Trainpoint",
      //     name: "Train/Trainpoint",
      //     component: () => import("../views/ArcTrain/TrainComponents/Trainpoint.vue"),
      // },
      // {
      //   // 证书管理
      //     path: "/Train/Traincertificate",
      //     name: "Train/Traincertificate",
      //     component: () => import("../views/ArcTrain/TrainComponents/Traincertificate.vue"),
      // },
      // {
      //   // 智能档案
      //     path: "/Train/TrainIntelligentfile",
      //     name: "Train/TrainIntelligentfile",
      //     component: () => import("../views/ArcTrain/TrainComponents/TrainIntelligentfile.vue"),
      // },

      {
        // 报表下载
        path: "/Train/Report",
        name: "Train/Report",
        component: () => import("../views/Train/Report"),
      },
      {
        // 报表下载
        path: "/Train/TrainingSystemFramework",
        name: "Train/TrainingSystemFramework",
        component: () => import("../views/ArcTrain/TrainingSystemFramework"),
      },
      {
        // 报表下载
        path: "/Train/Seting",
        name: "Train/Seting",
        component: () => import("@/views/Train/setting.vue"),
      },
      {
        // 报表下载
        path: "/Train/signInInformation",
        name: "Train/signInInformation",
        component: () => import("../views/ArcTrain/signInInformation"),
      },
      {
        // 培训报告
        path: "/Train/AssessmentReport",
        name: "Train/AssessmentReport",
        component: () => import("../views/ArcTrain/AssessmentReport"),
      },
      {
        // 培训分组
        path: "/Train/TrainGroup",
        name: "Train/TrainGroup",
        component: () => import("../views/ArcTrain/TrainGroup"),
      },
      {
        // 培训课堂
        path: "/Train/TrainCourse",
        name: "Train/TrainCourse",
        component: () => import("../views/ArcTrain/TrainCourse"),
      },
      {
        // 培训章节
        path: "/Train/TrainCourseChapter",
        name: "Train/TrainCourseChapter",
        component: () => import("../views/ArcTrain/TrainCourseChapter"),
      },
      {
        // 培训班
        path: "/Train/TrainCourseClass",
        name: "Train/TrainCourseClass",
        component: () => import("../views/ArcTrain/TrainCourseClass"),
      },
      {
        // 培训课程人员信息
        path: "/Train/TrainCourseclassPersonnelSituation",
        name: "Train/TrainCourseclassPersonnelSituation",
        component: () =>
          import("../views/ArcTrain/TrainCourseclassPersonnelSituation"),
      },
      {
        // 获取课程信息
        path: "/Train/TrainCourseDetails",
        name: "Train/TrainCourseDetails",
        component: () => import("../views/ArcTrain/TrainCourseDetails"),
      },
      //添加题目
      {
        path: "/examination/addTopic",
        name: "addTopic",
        component: () => import("../views/examination/addTopic"),
      },
      //题目
      {
        path: "/examination/topic",
        name: "topic",
        component: () => import("../views/examination/topic"),
      },
      //题目
      {
        path: "/examination/index",
        name: "examination/index",
        component: () => import("../views/examination/index"),
      }, //查看考生
      {
        path: "/examination/viewCandidates",
        name: "examination/viewCandidates",
        component: () => import("../views/examination/viewCandidates"),
      }, //题目
      {
        path: "/examination/addExams",
        name: "examination/addExams",
        component: () => import("../views/examination/addExams"),
      },
      {
        path: "/examination/addPractice",
        name: "examination/addPractice",
        component: () => import("../views/examination/addPractice"),
      },
      {
        path: "/Train/TraincoursewareManagement",
        name: "Train/TraincoursewareManagement",
        component: () =>
          import("../views/ArcTrain/TraincoursewareManagement.vue"),
      },
      {
        path: "/Train/Assessment",
        name: "Train/Assessment",
        component: () => import("../views/ArcTrain/Assessment.vue"),
      },
    ],
  },
  //薪酬
  {
    path: "/SalaryModule",
    name: "SalaryModule",
    component: () => import("../views/SalaryModule"),
    children: [
      {
        // 工资条管理
        path: "/Salary/SalarySetting",
        name: "Salary/SalarySetting",
        component: () => import("../views/Salary/Page/SalarySetting"),
      },
      // {
      //   // 计件工资设置
      //   path: "/Salary/pieceRateUnitPrice",
      //   name: "Salary/pieceRateUnitPrice",
      //   component: () => import("../views/Salary/pieceRateUnitPrice.vue"),
      // },
      //  {
      //   // 工资条管理
      //   path: "/Salary/Payrollmanagement",
      //   name: "Salary/Payrollmanagement",
      //   component: () => import("../views/Salary/Page/components/Payrollmanagement.vue"),
      // },
      // {
      //   // 计件工资设置
      //   path: "/Salary/Pieceworksetup",
      //   name: "Salary/Pieceworksetup",
      //   component: () => import("../views/Salary/Page/components/Pieceworksetup.vue"),
      // },
      // {
      //   // 薪酬数字报表
      //   path: "/Salary/ReportDownload",
      //   name: "Salary/ReportDownload",
      //   component: () => import("../views/Salary/Page/SalarySetting"),
      // },
      // {
      //   //数据抓取
      //   path: "/Salary/datacapturemd",
      //   name: "Salary/datacapturemd",
      //   component: () => import("../views/Salary/Page/components/datacapturemd.vue"),
      // },
      // {
      //   //发起算薪
      //   path: "/Salary/Initiatepayroll",
      //   name: "Salary/Initiatepayroll",
      //   component: () => import("../views/Salary/Page/components/Initiatepayroll.vue"),
      // },
      // {
      //   //计件工资计算与审批
      //   path: "/Salary/Calculationandapproval",
      //   name: "Salary/Calculationandapproval",
      //   component: () => import("../views/Salary/Page/components/Calculationandapproval.vue"),
      // },
      // {
      //   //计件工资
      //   path: "/Salary/piecerate",
      //   name: "Salary/piecerate",
      //   component: () => import("../views/Salary/Page/components/piecerate.vue"),
      // },
      // {
      //   //奖金核算
      //   path: "/Salary/Bonusaccounting",
      //   name: "Salary/Bonusaccounting",
      //   component: () => import("../views/Salary/Page/components/Bonusaccounting.vue"),
      // },
      // {
      //   //总额控制
      //   path: "/Salary/Aggregatecontrol",
      //   name: "Salary/Aggregatecontrol",
      //   component: () => import("../views/Salary/Page/components/Aggregatecontrol.vue"),
      // },
      // {
      //   //工资条
      //   path: "/Salary/salarysheet",
      //   name: "Salary/salarysheet",
      //   component: () => import("../views/Salary/Page/components/salarysheet.vue"),
      // },
      // {
      //   //薪酬预算
      //   path: "/Salary/Salarybudget",
      //   name: "Salary/Salarybudget",
      //   component: () => import("../views/Salary/Page/components/Salarybudget.vue"),
      // },
      // {
      //   //调薪管理
      //   path: "/Salary/Salaryadjustmentmanagement",
      //   name: "Salary/Salaryadjustmentmanagement",
      //   component: () => import("../views/Salary/Page/components/Salaryadjustmentmanagement.vue"),
      // },
      // {
      //   //薪等薪级
      //   path: "/Salary/Paygrade",
      //   name: "Salary/Paygrade",
      //   component: () => import("../views/Salary/Page/components/Paygrade.vue"),
      // },
      // {
      //   //定薪管理
      //   path: "/Salary/Fixedsalarymanagement",
      //   name: "Salary/Fixedsalarymanagement",
      //   component: () => import("../views/Salary/Page/components/Fixedsalarymanagement.vue"),
      // },
      {
        //
        path: "/Salary/PieceRateProduct",
        name: "Salary/PieceRateProduct",
        component: () => import("../views/Salary/Page/PieceRateProduct"),
      },
      {
        //
        path: "/Salary/PieceRateReview",
        name: "Salary/PieceRateReview",
        component: () => import("../views/Salary/Page/PieceRateReview"),
      },
      {
        path: "/Salary/PieceRateWage",
        name: "Salary/PieceRateWage",
        component: () => import("../views/Salary/Page/PieceRateWage"),
      },
      {
        // 奖金
        path: "/Salary/Page/Bonus",
        name: "Salary/Page/Bonus",
        component: () => import("../views/Salary/Page/Bonus.vue"),
      },
      {
        // 工资条管理
        path: "/Salary/Page/SalaryDataSources",
        name: "Salary/Page/SalaryDataSources",
        component: () => import("../views/Salary/Page/SalaryDataSources"),
      },
      {
        // 工资条
        path: "/Salary/Page/Payslip",
        name: "Salary/Page/Payslip",
        component: () => import("../views/Salary/Page/Payslip.vue"),
      },
      {
        // 奖金核算
        path: "/Salary/Page/bonusBudget",
        name: "Salary/Page/bonusBudget",
        component: () => import("../views/Salary/Page/bonusBudget.vue"),
      },
      {
        // 薪酬政策
        path: "/Salary/policy",
        name: "Salary/policy",
        component: () => import("../views/Salary/policy.vue"),
      },
      // {
      //   path: "/Salary/pieceRateUnitPrice",
      //   name: "pieceRateUnitPrice",
      //   component: () => import("../views/Salary/pieceRateUnitPrice"),
      // },
      // {
      //   // 报表下载
      //   path: "/Salary/ReportDownload",
      //   name: "Salary/ReportDownload",
      //   component: () => import("../views/Salary/ReportDownload.vue"),
      // },
    ],
  },
  //花名册
  {
    // 花名册
    path: "/RosterModule",
    name: "RosterModule",
    component: () => import("../views/RosterModule"),
    children: [
      {
        // 花名册
        path: "/Roster/Roster",
        name: "Roster/Roster",
        component: () => import("../views/Roster/Roster"),
      },
      {
        //application
        path: "/Roster/application",
        name: "Roster/application",
        component: () => import("../views/Roster/application"),
      },
    ],
  },
  //Roster 表格
  {
    path: "/Roster/luckySheet",
    name: "Roster/luckySheet",
    component: () => import("../views/Roster/luckySheet/index"),
  },
  // // 招聘大屏
  // {
  //   path: "/Recruitment/RecruitmentScreen",
  //   name: "Recruitment/RecruitmentScreen",
  //   component: () => import("../views/Recruitment/RecruitmentScreen"),
  // },
  // //绩效大屏
  // {
  //   path: "/Performance/PerformanceScreen",
  //   name: "Performance/PerformanceScreen",
  //   component: () => import("../views/Performance/PerformanceScreen"),
  // },
  // //培训大屏
  // {
  //   path: "/Train/TrainingScreen",
  //   name: "Train/TrainingScreen",
  //   component: () => import("../views/ArcTrain/TrainingScreen"),
  // },
  // {
  //   // 薪酬大屏
  //   path: "/Salary/SalaryScreen",
  //   name: "Salary/SalaryScreen",
  //   component: () => import("../views/Salary/SalaryScreen.vue"),
  // },
  {
    // 薪酬大屏
    path: "/Salary/SalaryLucky",
    name: "Salary/SalaryLucky",
    component: () => import("../views/Salary/SalaryLucky.vue"),
  },
  {
    path: "/brainPower",
    name: "brainPower",
    component: () => import("../views/brainPower"),
    children: [
      {
        path: "/brainPower/organStructure",
        name: "/brainPower/organStructure",
        component: () => import("../views/Workbench/son/organStructure.vue"),
      },
    ],
  },
  {
    path: "/brainPower1",
    name: "brainPower1",
    component: () => import("../views/brainPower/index1.vue"),
    children: [
      {
        path: "/brainPower1/knowledgeBase",
        name: "/brainPower1/knowledgeBase",
        component: () => import("../views/brainPower/knowledgeBase.vue"),
      },
      {
        path: "/brainPower1/knowledgeBase1",
        name: "/brainPower1/knowledgeBase1",
        component: () => import("../views/brainPower/knowledgeBase1.vue"),
      },
      {
        path: "/brainPower1/knowledgeBase2",
        name: "/brainPower1/knowledgeBase2",
        component: () => import("../views/brainPower/knowledgeBase2.vue"),
      },
    ],
  },
  //动态表单
  {
    path: "/workspace/index",
    name: "index",
    component: () => import("@/views/tissueTree/formDesin/Index.vue"),
    meta: { title: "OA工作流", viewport: viewport },
  },
  {
    path: "/workspace",
    name: "workspace",
    component: () => import("@/views/tissueTree/formDesin/wokespce/index.vue"),
    meta: { title: "工作区", viewport: viewport },
    redirect: "/workspace/approval",
    children: [
      {
        path: "approval",
        name: "approval",
        component: () =>
          import("@/views/tissueTree/formDesin/wokespce/approval/index.vue"),
        meta: { title: "审批列表", viewport: viewport },
      },
      {
        path: "todo",
        name: "todo",
        component: () =>
          import("@/views/tissueTree/formDesin/wokespce/todo/index.vue"),
        meta: { title: "待我处理", viewport: viewport },
      },
      {
        path: "apply",
        name: "apply",
        component: () =>
          import("@/views/tissueTree/formDesin/wokespce/apply/index.vue"),
        meta: { title: "我发起的", viewport: viewport },
      },
      {
        path: "about",
        name: "about",
        component: () =>
          import("@/views/tissueTree/formDesin/wokespce/about/index.vue"),
        meta: { title: "关于我的", viewport: viewport },
      },

      {
        path: "cc",
        name: "cc",
        component: () =>
          import("@/views/tissueTree/formDesin/wokespce/cc/index.vue"),
        meta: { title: "抄送我的", viewport: viewport },
      },
      {
        path: "submit",
        name: "submit",
        component: () =>
          import("@/views/tissueTree/formDesin/wokespce/submit/index.vue"),
        meta: { title: "数据管理", viewport: viewport },
      },
    ],
  },
  {
    path: "/workspace/process/instance/tabs",
    name: "processInstanceTabs",
    component: () =>
      import(
        "@/views/tissueTree/formDesin/wokespce/process/ProcessInstanceTabs.vue"
      ),
    meta: { title: "流程详情", viewport: viewport },
  },
  {
    path: "/formsPanel",
    name: "formsPanel",
    component: () =>
      import("@/views/tissueTree/formDesin/admin/FormsPanel.vue"),
    meta: { title: "表单列表", viewport: viewport },
  },
  {
    path: "/admin/design",
    name: "design",
    component: () =>
      import("@/views/tissueTree/formDesin/admin/FormProcessDesign.vue"),
    meta: { title: "表单流程设计", viewport: viewport },
  },
  // Ai人力
  {
    path: "/WorkbenchModule",
    name: "WorkbenchModule",
    component: () => import("../views/WorkbenchModule"),
    children: [
      // Ai人力首页
      {
        path: "/Workbench/Aihomepage",
        name: "Workbench/Aihomepage",
        component: () => import("../views/Workbench/Aihomepage.vue"),
        children: [
          // {
          //   path: "/Workbench/organStructure",
          //   name: "/Workbench/organStructure",
          //   component: () => import("../views/Workbench/son/organStructure.vue"),
          // },
          {
            path: "/Workbench/knowledgeBase",
            name: "/Workbench/knowledgeBase",
            component: () => import("../views/brainPower/knowledgeBase.vue"),
          },
          {
            path: "/Workbench/knowledgeBase2",
            name: "/Workbench/knowledgeBase2",
            component: () => import("../views/brainPower/knowledgeBase2.vue"),
          },

          {
            path: "/Workbench/knowledgeBase1",
            name: "/Workbench/knowledgeBase1",
            component: () => import("../views/brainPower/knowledgeBase1.vue"),
          },

          {
            path: "/personalHomepage",
            name: "/personalHomepage",
            component: () => import("../views/Workbench/Myhome.vue"),
          },
          {
            path: "/Workbench/myhome",
            name: "/Workbench/myhome",
            component: () => import("../views/Workbench/Myhome.vue"),
          },
          // 我的会议
          {
            path: "/Workbench/myMeeting",
            name: "/Workbench/myMeeting",
            component: () => import("../views/Workbench/myMeeting.vue"),
          },
          // 会议预约
          {
            path: "/Workbench/meeting",
            name: "/Workbench/meeting",
            component: () => import("../views/Workbench/meeting.vue"),
          },
          // 日报
          {
            path: "/Workbench/Daily",
            name: "/Workbench/Daily",
            component: () => import("../views/Workbench/Daily.vue"),
          },
          // 月报
          {
            path: "/Workbench/Monthly",
            name: "/Workbench/Monthly",
            component: () => import("../views/Workbench/Monthly.vue"),
          },
          // 我的假期
          {
            path: "/Workbench/Myvacation",
            name: "/Workbench/Myvacation",
            component: () => import("../views/Workbench/Myvacation.vue"),
          },

          {
            path: "/Workbench/shenhe",
            name: "shenhe",
            component: () => import("../views/Workbench/son/shenhe.vue"),
            redirect: "/Workbench/shenhe/approval",
            children: [
              {
                path: "/Workbench/shenhe/approval",
                name: "/Workbench/shenhe/approval",
                component: () =>
                  import(
                    "@/views/tissueTree/formDesin/wokespce/approval/index.vue"
                  ),
              },
              {
                path: "/Workbench/shenhe/todo",
                name: "/Workbench/shenhe/todo",
                component: () =>
                  import(
                    "@/views/tissueTree/formDesin/wokespce/todo/index.vue"
                  ),
              },
              {
                path: "/Workbench/shenhe/apply",
                name: "/Workbench/shenhe/apply",
                component: () =>
                  import(
                    "@/views/tissueTree/formDesin/wokespce/apply/index.vue"
                  ),
              },
              {
                path: "/Workbench/shenhe/about",
                name: "/Workbench/shenhe/about",
                component: () =>
                  import(
                    "@/views/tissueTree/formDesin/wokespce/about/index.vue"
                  ),
              },
              {
                path: "/Workbench/shenhe/cc",
                name: "/Workbench/shenhe/cc",
                component: () =>
                  import("@/views/tissueTree/formDesin/wokespce/cc/index.vue"),
              },
              {
                path: "/Workbench/shenhe/submit",
                name: "/Workbench/shenhe/submit",
                component: () =>
                  import(
                    "@/views/tissueTree/formDesin/wokespce/submit/index.vue"
                  ),
              },
            ],
          },
          {
            //我的申请
            path: "/Workbench/ApplyFor",
            name: "ApplyFor",
            component: () => import("../views/ProcessCenter/ApplyFor.vue"),
          },
          // {
          //   //人力报表
          //   path: "/Workbench/decisionmaking",
          //   name: "DecisionMaking",
          //   component: () => import("../views/CommandCenter/DecisionMaking.vue"),
          // },
          // {
          //   //员工档案
          //   path: "/Workbench/record",
          //   name: "record",
          //   component: () => import("../views/Workbench/son/AipagerightRecord.vue"),
          // },
          {
            //排班
            path: "/Workbench/arrange",
            name: "arrange",
            component: () => import("../views/Workbench/son/Arrange.vue"),
          },
          {
            //预入职
            path: "/Workbench/perEntry",
            name: "perEntry",
            component: () => import("../views/Workbench/perEntry.vue"),
          },
          {
            //入转
            path: "/Workbench/Aipageright",
            name: "Workbench/Aipageright",
            component: () => import("../views/Workbench/son/Aipageright.vue"),
          },
          {
            //转正
            path: "/Workbench/becomemember",
            name: "Workbench/becomemember",
            component: () => import("../views/Workbench/son/becomemember.vue"),
          },
          {
            //薪酬
            path: "/Workbench/salary",
            name: "Workbench/salary",
            component: () => import("../views/Workbench/son/salary.vue"),
          },
          {
            //调离
            path: "/Workbench/Transfer",
            name: "Workbench/Transfer",
            component: () => import("../views/Workbench/son/Transfer.vue"),
          },
          {
            //考勤
            path: "/Workbench/Kaoqin",
            name: "Workbench/Kaoqin",
            component: () => import("../views/Workbench/son/Kaoqin.vue"),
          },
          {
            //审批
            path: "/Workbench/ExamineApprove",
            name: "Workbench/ExamineApprove",
            component: () =>
              import("../views/Workbench/son/ExamineApprove.vue"),
          },
          {
            //员工自助
            path: "/Workbench/AiSelfHelp",
            name: "/Workbench/AiSelfHelp",
            component: () => import("../views/Workbench/son/AiSelfHelp.vue"),
          },
          {
            //员工自助
            path: "/Workbench/dataBoard",
            name: "/Workbench/dataBoard",
            component: () => import("../views/Workbench/dataBoard.vue"),
          },
        ],
      },
      // {
      //   //驾驶仓
      //   path: "/Workbench/Index",
      //   name: "Workbench/Index",
      //   component: () => import("../views/Workbench/Index"),
      // },
      {
        //工作交接单
        path: "/Workbench/Workreceipt",
        name: "Workbench/Workreceipt",
        component: () => import("../views/Workbench/Workreceipt"),
      },
      {
        //调换班申请
        path: "/Workbench/ShiftChangeApplication",
        name: "Workbench/ShiftChangeApplication",
        component: () => import("../views/Workbench/ShiftChangeApplication"),
      },
      {
        //审批
        path: "/Workbench/examine",
        name: "Workbench/examine",
        component: () => import("../views/Workbench/examine"),
      },
      {
        //薪酬统计
        path: "/Workbench/CompensationStatistics",
        name: "Workbench/CompensationStatistics",
        component: () => import("../views/Workbench/CompensationStatistics"),
      },
      {
        //人员档案
        path: "/Workbench/PersonnelFile",
        name: "Workbench/PersonnelFile",
        component: () => import("../views/Workbench/PersonnelFile"),
      },
      {
        //外出记录
        path: "/Workbench/GooutRecord",
        name: "Workbench/GooutRecord",
        component: () => import("../views/Workbench/GooutRecord"),
      },
      {
        // 通知公告
        path: "/Workbench/Notice",
        name: "Workbench/Notice",
        component: () => import("../views/Workbench/Notice"),
      },
      {
        // 公文查阅
        path: "/Workbench/ReferDocument",
        name: "Workbench/ReferDocument",
        component: () => import("../views/Workbench/ReferDocument"),
      },
      {
        // 外出出差
        path: "/Workbench/BusinessRecord",
        name: "Workbench/BusinessRecord",
        component: () => import("../views/Workbench/BusinessRecord"),
      },
      {
        // 招聘需求
        path: "/Workbench/PersonnelRequirement",
        name: "Workbench/PersonnelRequirement",
        component: () => import("../views/Workbench/PersonnelRequirement"),
      },
      {
        // 定薪申请
        path: "/Workbench/WageApplication",
        name: "Workbench/WageApplication",
        component: () => import("../views/Workbench/WageApplication"),
      },
      {
        // 排班管理
        path: "/Workbench/workforceManagement",
        name: "Workbench/workforceManagement",
        component: () => import("../views/Workbench/workforceManagement"),
      },

      //
      {
        // 招聘需求
        path: "/Workbench/EntryDocument",
        name: "Workbench/EntryDocument",
        component: () => import("../views/Workbench/EntryDocument"),
      },
      {
        // 培训需求
        path: "/Workbench/TrainingApplication",
        name: "Workbench/TrainingApplication",
        component: () => import("../views/Workbench/TrainingApplication"),
      },
      {
        // 离职申请
        path: "/Workbench/SeparationApplicationForm",
        name: "Workbench/SeparationApplicationForm",
        component: () => import("../views/Workbench/SeparationApplicationForm"),
      },

      {
        // 转正详情
        path: "/Workbench/workeratify",
        name: "Workbench/workeratify",
        component: () => import("../views/Workbench/workeratify"),
      },
      {
        // 补卡申请
        path: "/Workbench/cardreplacement",
        name: "Workbench/cardreplacement",
        component: () => import("../views/Workbench/cardreplacement"),
      },
      {
        // 外出申请
        path: "/Workbench/goout",
        name: "Workbench/goout",
        component: () => import("../views/Workbench/goout"),
      },
      {
        // 续签合同
        path: "/Workbench/Renewal",
        name: "Workbench/Renewal",
        component: () => import("../views/Workbench/Renewal"),
      },
      {
        // 转正详情
        path: "/Workbench/askforleave",
        name: "Workbench/askforleave",
        component: () => import("../views/Workbench/askforleave"),
      },
      {
        // 复职资料
        path: "/Workbench/bereinstated",
        name: "Workbench/bereinstated",
        component: () => import("../views/Workbench/bereinstated"),
      },
      {
        // 入职申请
        path: "/Workbench/entryregistration",
        name: "Workbench/entryregistration",
        component: () => import("../views/Workbench/entryregistration"),
      },
      {
        // 身份证读卡器
        path: "/Workbench/idread",
        name: "Workbench/idread",
        component: () => import("../views/Workbench/idread"),
      },

      {
        // 考勤统计
        path: "/Workbench/overtime",
        name: "Workbench/overtime",
        component: () => import("../views/Workbench/overtime.vue"),
      },
      {
        // 今日会议
        path: "/Workbench/Conference",
        name: "Workbench/Conference",
        component: () => import("../views/Workbench/Conference"),
      },
    ],
  },
  {
    path: "/tissueTree/workFlow",
    name: "tissueTree/workFlow",
    component: () => import("../views/tissueTree/workflow.vue"),
  },

  //流程自动化
  {
    path: "/ProcessCenter",
    name: "ProcessCenter",
    component: () => import("../views/ProcessCenterModule"),
    children: [
      {
        // 我的待办
        path: "/ProcessCenter/todo",
        name: "/ProcessCenter/todo",
        component: () => import("../views/ProcessCenter/todo"),
      },
      {
        // 我发起的
        path: "/ProcessCenter/create",
        name: "ProcessCenter/create",
        component: () => import("../views/ProcessCenter/create"),
      },
      {
        // 我处理的
        path: "/ProcessCenter/transactors",
        name: "ProcessCenter/transactors",
        component: () => import("../views/ProcessCenter/transactors"),
      },
      {
        // 抄送我的
        path: "/ProcessCenter/CarbonCopy",
        name: "ProcessCenter/CarbonCopy",
        component: () => import("../views/ProcessCenter/CarbonCopy"),
      },
      {
        // 发起新的流程
        path: "/ProcessCenter/launch",
        name: "ProcessCenter/launch",
        component: () => import("../views/ProcessCenter/launch"),
      },
      {
        // 通知消息
        path: "/ProcessCenter/News",
        name: "ProcessCenter/News",
        component: () => import("../views/ProcessCenter/News"),
      },
      {
        // 我的申请
        path: "/ProcessCenter/ApplyFor",
        name: "ProcessCenter/ApplyFor",
        component: () => import("../views/ProcessCenter/ApplyFor"),
      },
    ],
  },
  {
    path: "/CommandCenter/DecisionMaking",
    name: "CommandCenter/DecisionMaking",
    component: () => import("../views/CommandCenter/DecisionMaking"),
  },
  {
    path: "/CommandCenter/HumanResources",
    name: "CommandCenter/HumanResources",
    component: () => import("../views/CommandCenter/HumanResources"),
  },
  {
    path: "/CommandCenter/BasicHumanResourcesData",
    name: "CommandCenter/BasicHumanResourcesData",
    component: () => import("../views/CommandCenter/BasicHumanResourcesData"),
  },
  {
    path: "/customerManagement",
    name: "customerManagement",
    component: () => import("../views/tissueTree/customerManagement"),
  },

  {
    path: "/pieceRateReview",
    name: "pieceRateReview",
    component: () => import("../views/Salary/Page/PieceRateReview1"),
  },
  {
    path: "*",
    component: () => import("@/views/notfound"),
  },
];

const router = new VueRouter({
  routes,
});

let createRouter = () =>
  new VueRouter({
    routes: routes, // 路由路径
    scrollBehavior: () => ({ y: 0 }), // 在切换时定位路由滚动条的位置
  });

router.beforeEach(async (to, from, next) => {
  // console.log(to,store.state.isRoles);
  // if(to.path == "/AiTalentProfileReport") {
  //   console.log('走这里');
  //   next('/AiTalentProfileReport');
  // }

  //判断是否是登录状态
  if (localStorage.getItem("arcUser")) {
    if (
      to.path == "/Login" ||
      to.path == "/pieceRateReview" ||
      to.path == "/login" ||
      to.path == "/Train/TrainingRating" ||
      to.path == "/TrainingCheckIn" ||
      to.path == "/evaluation" ||
      to.path == "/AiTalentProfileReport"
    ) {
      next();
    } else {
      //判断是获取过权限

      if (!store.state.isRoles) {
        await store.dispatch("getRoles");
        next({ ...to, replace: true });
      } else {
        if (to.name == "home") {
          let userType = localStorage.getItem("arcUserType");
          if (userType == null) {
            next("/login");
          }
          if (userType > 1) {
            next();
          } else {
            next("/personalHomepage");
          }
        } else if (to.name == "personalHomepage") {
          let userType = localStorage.getItem("arcUserType");
          if (userType == 1) {
            next();
          } else {
            next("/home");
          }
        } else {
          next();
        }
      }
    }
  } else {
    if (
      to.path == "/AiTalentProfileReport" ||
      to.path == "/evaluation" ||
      to.path == "/login"
    ) {
      next();
      return;
    } else {
      next("/login");
    }
  }
});

export function addRoutesInfo(parent) {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher;
  router.addRoutes(parent);
}
export default router;
