import { pako_unzip } from "@/utils/pako";
import { getSysFile } from "@/airencaiapi/ArcFile";
import router from '@/router/index'
import store from "@/store";

export async function getPermissionList(id, that) {
  //拿到用户信息
  let user = pako_unzip(localStorage.getItem("arcUser"));

  if(user == null || user == undefined || user == "" || user == "undefined") return
    
  //logo
  let file = await getSysFile(user.companyId);
  // console.log('file', file);
  
  if (file.code == 403) {
    return
  }
  if (file.code == 200 && file.data.logo != null && file.data.logo != "") {
    that.$store.commit("set_logo", file.data.logo);
  }
  store.dispatch("getRoles");
  //放入用户信息
  that.$store.commit("SET_USER", user);
}