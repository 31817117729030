import axios from "./airencaiHttp";

//增加公司文件
export function create(data){
    return axios.post("/file/create",data)
}
//修改公司文件
export function updateFile(data){
    return axios.post("/file/update",data)
}

//修改公司文件
export function getSysFile(id){
    return axios.get("/file/getSysFile/"+id)
}

// 上传图片
export function sc(data){
    return axios.post("/file/sc",data)
}

// 删除文件
export function deleteFile(data){
    return axios.post("/file/deleteFile",data)
}

// 删除文件
export function requestUrlToBase64(data){
    return axios.post("/file/requestUrlToBase64",data)
}

// 更新公司名称 ​/file​/updateCompanyName

export function updateCompanyName(data){
    return axios.post("/file/updateCompanyName",data)
}
