import Vue from "vue";
import Vuex from "vuex";
import { pako_unzip } from "@/utils/pako";
import { addRoutesInfo } from "@/router";
import { getPermissions } from "@/airencaiapi/ArcMenu";
Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    approveList: JSON.parse(localStorage.getItem("approveList")),
    staffdepartment: [],
    seletcompentVel: [],
    staffcount: 0,
    formIdApp: localStorage.getItem("formIdApp"),
    sehnhedata: [],
    selectvaldata: [],
    nodeMap: new Map(),
    isEdit: null,
    selectedNode: {},
    selectFormItem: null,
    design: {},
    runningList: [],
    noTakeList: [],
    endList: [],
    diagramMode: "design", //以上是动态表单所需的属性
    user: { userType: 0 }, //用户信息
    mode: "SigleText",
    models: [],
    activeModelId: "",
    forms: {},
    modeId: "",
    result: null,
    luckySheetList: null, //表格列表数据
    mockGraphData: [], //精准需求数据
    mockLink: [], //精准需求数据
    permissionList: ["/Setup/roles", "/home"], //权限列表
    isRoles: false,
    featureList: [], //功能列表
    tableName: [], //表格名字
    personageList: null, //个人数据
    fullscreenLoading: false, //控制Loading
    submeterList: null, //分表数据
    corporate: null, //公司信息
    filialeList: null, //分公司表数据
    penetration: null, //数据穿透表
    resume: null, //个人简历
    manPower: null,
    echarts: null,
    headIndex: localStorage.getItem("headIndex"),
    logo: "https://airencai.oss-cn-beijing.aliyuncs.com/20230808/2023/8/8/8d1a04e2bfcd4b6aa6454fbe60be1f37icon.png",
    department: null, //搜索所有部门
    isCollapse: null, //控制头部展开折叠
    largeScreen: null, //控制大屏按钮显隐
    showLook: false, //控制人才储备库上传简历
    routeList: [],
    headStr: localStorage.getItem("headStr"),
    checkChildren: "0",
    AssessmentHeader: "0",
    INPUTONCE: "",
  },
  getters: {},
  mutations: {
    SET_design(state, val) {
      state.design = val;
    },
    approveListVelF(state, val) {
      state.approveList = val;
    },
    seletformIdApp(state, val) {
      state.formIdApp = localStorage.setItem("formIdApp", val);
    },
    seletcompentVelF(state, val) {
      state.seletcompentVel.push(val);
    },
    seletONCE(state, val) {
      state.INPUTONCE = val;
    },
    selectfashen(state, val) {
      localStorage.setItem("approveList", JSON.stringify(val));
      state.sehnhedata = val;
    },
    SelectValNode(state, val) {
      state.selectvaldata = val;
    },
    selectedNode(state, val) {
      state.selectedNode = val;
    },
    loadForm(state, val) {
      state.design = val;
    },
    setIsEdit(state, val) {
      state.isEdit = val;
    }, //以上是动态表单所需的属性
    set_Assess(state, payload) {
      state.checkChildren = payload;
      state.AssessmentHeader = "2";
    },
    set_InitAss(state, payload) {
      state.AssessmentHeader = payload;
      state.checkChildren = "0";
    },
    set_isRoles(state, payload) {
      state.isRoles = payload;
    },
    set_routeList(state, payload) {
      state.routeList = payload;
    },
    set_logo(state, payload) {
      state.logo = payload;
    },
    updateValue(state, payload) {
      state.value = payload;
    },
    SET_Manpower(state, payload) {
      state.manPower = payload;
    },
    SET_result(state, payload) {
      state.result = payload;
    },
    SET_Echarts(state, payload) {
      state.echarts = payload;
    },
    SET_RESUME(state, payload) {
      state.resume = payload;
    },
    SET_USER(state, payload) {
      state.user = payload;
    },
    change_USER(state, payload) {
      state.user = { ...state.user, ...payload };
    },
    SET_MODE(state, payload) {
      state.mode = payload;
    },
    SET_MODELS(state, payload) {
      state.models = payload;
    },
    SET_Department(state, payload) {
      state.staffdepartment = payload;
    },
    SET_ACTIVEMODELID(state, payload) {
      state.activeModelId = payload;
    },
    SET_FROMMODEl(state, payload) {
      state.forms = payload;
    },
    SET_MODEID(state, payload) {
      state.modeId = payload;
    },
    SET_LuckySheetList(state, payload) {
      state.luckySheetList = payload;
    },
    SET_mockGraphData(state, payload) {
      state.mockGraphData = payload;
    },
    SET_mockLink(state, payload) {
      state.mockLink = payload;
    },
    SET_permissionList(state, payload) {
      state.permissionList = payload;
    },
    SET_featureList(state, payload) {
      state.featureList = payload;
    },
    SET_LuckyData(state, payload) {
      state.luckyData = payload;
    },
    SET_LuckyTable(state, payload) {
      state.luckyTable = payload;
    },
    SET_tableName(state, payload) {
      state.tableName = payload;
    },
    SET__id(state, payload) {
      state._id = payload;
    },
    SET_fullscreenLoading(state, payload) {
      state.fullscreenLoading = payload;
    },
    SET_personageList(state, payload) {
      state.personageList = payload;
    },
    SET_submeterList(state, payload) {
      state.submeterList = payload;
    },
    SET_corporate(state, payload) {
      state.corporate = payload;
    },
    SET_filialeList(state, payload) {
      state.filialeList = payload;
    },
    SET_isCollapse(state, payload) {
      state.isCollapse = payload;
    },
    SET_headIndex(state, payload) {
      localStorage.setItem("headIndex", payload);
      state.headIndex = payload;
    },
    SET_headStr(state, payload) {
      localStorage.setItem("headStr", payload);
      state.headStr = payload;
    },
    SET_department(state, payload) {
      state.department = payload;
    },
    SET_largeScreen(state, payload) {
      state.largeScreen = payload;
    },
    SET_showLook(state, payload) {
      state.showLook = payload;
    },
  },

  actions: {
    async getRoles() {
      return new Promise((resolve, reject) => {
        if (localStorage.getItem("arcUser") == null) {
          router.push("/login");
          resolve([]);
        } else {
          let user = pako_unzip(localStorage.getItem("arcUser"));
          //请求个人信息数据
          getPermissions(user.id)
            .then((res) => {
              this.commit("set_isRoles", true);
              //对个人信息做处理
              if (res.code == 200) {
                // 将所有的数据转化为树形结构
                let loadView = (view) => {
                  return (resolve) => require([`@/views/${view}`], resolve);
                };
                let list = JSON.parse(JSON.stringify(res.data));

                let routerList = [
                  {
                    id: "1222320",
                    path: "/Performance/evaluationcorrection",
                    authName: "评价修正",
                    pid: "3",
                    isMenu: false,
                    url: null,
                    pcComponent: "Performance/evaluationcorrection",
                    imgSrc: null,
                    children: [],
                    name: "Performance/evaluationcorrection",
                  },
                  {
                    id: "1222329",
                    path: "/brainPower/review",
                    authName: "人员评审",
                    pid: "160",
                    isMenu: false,
                    url: null,
                    pcComponent: "brainPower/review",
                    imgSrc: null,
                    children: [],
                    name: "brainPower/review",
                  },
                  {
                    id: "1222321",
                    path: "/brainPower/talentStandard",
                    authName: "人才标准",
                    pid: "160",
                    isMenu: false,
                    url: null,
                    pcComponent: "brainPower/talentStandard",
                    imgSrc: null,
                    children: [],
                    name: "brainPower/talentStandard",
                  },
                  {
                    id: "1222322121",
                    path: "/brainPower/talentlabel",
                    authName: "人才标签",
                    pid: "160",
                    isMenu: false,
                    url: null,
                    pcComponent: "brainPower/talentlabel",
                    imgSrc: null,
                    children: [],
                    name: "brainPower/talentlabel",
                  }
                ];

                if (user.userName == "ZYAK8001" && user.name == "邱明俊") {
                  list.push({
                    id: "12150",
                    path: "/tissueTree/administrator",
                    authName: "网站管理员",
                    pid: "10",
                    isMenu: false,
                    url: null,
                    pcComponent: "tissueTree/administrator",
                    imgSrc: null,
                    children: [],
                    name: "tissueTree/administrator",
                  });
                }

                list = [...list, ...routerList]
                let sIist = [];
                let includPath = [
                  "/Recruitment/RecruitmentScreen",
                  "/Train/TrainingScreen",
                  "/Performance/PerformanceScreen",
                  "/Salary/SalaryScreen",
                ];
                let parent = list.filter((item) => {
                  if (item.path != null) {
                    if (includPath.includes(item.path)) {
                      sIist.push(item);
                    }
                    if (item.pid == null) {
                      if ("path" in item && item.path != null) {
                        item.name = item.path.substring(1);

                        item.component = loadView(item.pcComponent);
                      }
                      return item;
                    }
                  }
                });

                function convertTree(menus, menu) {
                  menu.children = [];
                  let ids = [];
                  menus.forEach((i) => {
                    if (i.path != null) {
                      if (i.pid == menu.id) {
                        if ("path" in i && i.path != null) {
                          i.name = i.path.substring(1);
                          i.component = loadView(i.pcComponent);
                        }
                        menu.children.push(i);
                        ids.push(i.id);
                      }
                    }
                  });
                  menus = menus.filter((i) => {
                    if (!ids.includes(i.id)) {
                      return i;
                    }
                  });
                  if (menu.children.length > 0) {
                    menu.children.forEach((i) => convertTree(menus, i));
                  }
                }

                parent.forEach((item, index) => {
                  convertTree(list, item);
                });

                // console.log([...sIist, ...parent]);
                parent.map((op) => {
                  if (op.path == "/WorkbenchModule") {
                    let arr = op.children;
                    op.children = [];
                    op.children.push({
                      authName: "入职",
                      imgSrc: null,
                      children: arr,
                      name: "Workbench/Aihomepage",
                      path: "/Workbench/Aihomepage",
                      component: () =>
                        import("../views/Workbench/Aihomepage.vue"),
                    });
                  }
                });

                addRoutesInfo([...sIist, ...parent]);
                parent = parent.filter((i) => {
                  if (i.children.length > 0) {
                    return true;
                  }
                });
                // console.log('home页数据：',res.data);

                let index = res.data.findIndex((item) => {
                  return item.authName === "AI人力";
                });
                res.data[index].path = "/Workbench/Index";

                this.commit("SET_personageList", res.data);
                this.commit("set_routeList", parent);
                resolve(parent);
              } else {
                this.commit("set_isRoles", true);
                this.commit("SET_personageList", []);
                this.commit("set_routeList", []);
                resolve([]);
              }
            })
            .catch((error) => {
              this.commit("set_isRoles", true);
              this.commit("SET_personageList", []);
              this.commit("set_routeList", []);
              resolve([]);
            });
        }
      });
    },
  },
  modules: {},
});
